
















import { Component, Vue } from "vue-property-decorator";
import Input from "@/views/search/components/input.vue";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: { Input },
})
export default class equipmentTest extends Vue {
  official_id = 0;
  activity_id = 0;

  mounted() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new VConsole();
    } catch (e) {
      console.log(e);
    }
  }

  handleAdd() {
    InteractionLib.flutterCall(
      "addWishToSteam",
      String(this.official_id),
      Number(this.activity_id)
    );
  }
}
