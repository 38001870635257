<template>
  <div class="flex-row">
    <div class="search">
      <Icon color="#000" name="search" class="icon" size="20" />
      <input
        ref="inputB"
        @keyup.enter="search"
        v-model="inputData"
        type="text"
        class="input"
      />
      <img
        v-if="inputData.length !== 0"
        class="clear"
        @click="clear"
        src="https://cdn.vgn.cn/static/Official-website/clear.png"
        alt=""
      />
    </div>
    <div
      style="font-size: 16px; margin-right: 10px; font-weight: 800"
      @click="search"
    >
      搜索
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { Icon } from "vant";
import SearchApi from "@/api/search.api";
@Component({
  components: { Icon },
})
export default class Input extends Vue {
  @Prop() inputcontent;
  @Ref("inputB") inputB;
  @Watch("inputcontent", { immediate: true, deep: true })
  WatchInputcontent() {
    this.inputData = this.inputcontent;
  }

  @Watch("inputData", { immediate: true, deep: true })
  WatchinputData() {
    this.$emit("inputChange", this.inputData);
  }
  inputData = "";

  clear() {
    this.inputB.focus();
    this.inputData = "";
    this.$emit("clear");
  }
  sumbit() {}
  async search() {
    this.inputData = this.inputData.replace(/\s*/g, "");
    if (this.inputData == "") {
      return;
    }
    const res = await SearchApi.test({
      keyword: this.inputData,
    });
    this.$emit("search", {
      content: this.inputData,
      res,
    });
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.search {
  width: 85%;
  height: 32px;
  background: #ffffff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  position: relative;
  .icon {
    margin-left: 10px;
  }
  .input {
    font-size: 13px;
    border: none;
    width: 80%;
    color: #000;
    margin-left: 5px;
  }
  .clear {
    position: absolute;
    right: 9px;
    top: 6 px;
    width: 15px;
    height: 15px;
  }
}
</style>
